.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 24px 24px;
  background-image:
    linear-gradient(to right, whitesmoke 1px, transparent 1px),
    linear-gradient(to bottom, whitesmoke 1px, transparent 1px);
}

.descriptions {
  background-color: white;
  max-width: 640px;
}